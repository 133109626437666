<template>
  <div>
    <a class="bg-dark-green text-green flex flex-row justify-between font-title py-3.5 px-7" href="#landing">
      <div class="flex flex-col text-left">
        <div  class="text-2xl">Morgane Joulié</div>
        <h3>Psychologue clinicienne</h3>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: 'HeaderTopSmall',
}
</script>

