<template>
  <div class="bg-dark-green flex flex-col text-green font-title items-center p-4 gap-6">
    <div class="flex flex-col items-center">
      <div>Morgane Joulié - Psychologue clinicienne</div>
      <div>Numéro ADELI : 789317237</div>
    </div>
    <div class="text-[8px] text-black">
      site développé par Constance Agonsè - développeuse web
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterEnd',
}
</script>

