<template>
  <div class="">
  </div>
  <router-view />
</template>


<script>
// @ is an alias to /src

</script>
